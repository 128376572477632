import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store";
import  { askForLogin, logout } from '../../../features/auth/authSlice';
import  { toggleMenu, closeMenu, showLoginToast }  from '../../../features/config'

import { openUserUpdateProfile, closeUserUpdateProfile } from '../../../features/userNick/userNickSlice'

import NavLinkC from "../../components/NavLinkC";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import UserAvatarUpload from "../user.avatarupload";
import UserUpdateProfile from "../user.update.profile";
import { useGetApiAuthRefreshTokenQuery, useLazyGetApiAuthRefreshTokenQuery } from "../../api/authApi";
const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT as string;

const Header = () => {
    
    const dispatch = useAppDispatch();
    const config = useAppSelector( p => p.config);
    const user = useAppSelector(p => p.auth.user);
    const userToken = useAppSelector(p => p.auth.userToken);
    const navigate = useNavigate();
    const location = useLocation();
    const loginRequested = useAppSelector(p => p.auth.askForLogin);
    const profileChanged = useAppSelector(p => p.auth.ctr);
    const menuShowHide = () => {

        dispatch( toggleMenu() )

    }
    const [isToastShown, setIsToastShown] = useState(false);

   

    useEffect( () => {

        if(loginRequested > 0 && !config.loginToastUp ) {
            console.log("showing ask papap");
            toast.warn(
                () => ( 
                    <div>Some features require authentication, please 
                        <NavLink className={'btn btn-sm btn-success'} to="/login"> login </NavLink> or 
                        <NavLink className={'btn btn-sm btn-success'} to="/login"> signup </NavLink> to continue...</div>),
                {  autoClose:false   });
                dispatch(showLoginToast());
                dispatch(askForLogin(0));
        }

    },[loginRequested, dispatch, showLoginToast]);

    const [avatarModel, setAvatarModal] = useState({open:false });
    
    const nickModel = useAppSelector((state) => state.userNick.userUpdateProfileOpen);

    return(<header id="header" className="site-header site-header--bottom">
    <div className="header-logo header-logo--img">
        <a href="/"><img src="/assets/img/logo.png" srcSet="/assets/img/logo@2x.png 2x" alt="Coin Synthesis" /></a>
    </div>    
    <nav className="main-nav">
        <ul className="main-nav__list">
            <li className=""><NavLinkC to={'./'} >Home</NavLinkC></li>
        
            <li className=""><NavLinkC to={'./currencies'}>Cryptocurrencies</NavLinkC>
                <ul className="main-nav__sub">
                <li className=""><NavLinkC to={'./currencies/spot'}>Ranking</NavLinkC></li>				                	
				<li className=""><NavLinkC to={'./currencies/currenciesgainerlooser'}>Gainers & Losers</NavLinkC></li>						
                <li className=""><NavLinkC to={'./currencies/changes'}>Live Changes</NavLinkC></li>			
                </ul>
                <span className="main-nav__toggle">&nbsp;</span>
            </li>
    
            <li className=""><NavLinkC to={'./exchanges'}>Exchanges</NavLinkC>
                <ul className="main-nav__sub">
                    <li className=""><NavLinkC to={'./exchanges/cryptoexchanges'}>Crypto Exchanges</NavLinkC></li>				
                    <li className="">
                        <NavLinkC to={'./exchanges/dexexchanges'}>Decentralized Exchanges</NavLinkC>
                    </li>
                </ul>
                <span className="main-nav__toggle">&nbsp;</span>
            </li>
            <li className="">
                <NavLinkC to={'./tools'}>Tools</NavLinkC>
                <ul className="main-nav__sub">				
                    <li className=""><NavLinkC to={'./tools/poster'}>Poster</NavLinkC></li>				
                    <li className=""><NavLinkC to={'./tools/pnl'}>PNL</NavLinkC></li>				
                    <li className=""><NavLinkC to={'./tools/target-price'}>Target Price</NavLinkC></li>				
                    <li className=""><NavLinkC to={'./tools/liquidation-price'}>Liquidation Price</NavLinkC></li>				
                    <li className=""><NavLinkC to={'./tools/max-open'}>Max Open</NavLinkC></li>				
                    <li className=""><NavLinkC to={'./tools/open-price'}>Open Price</NavLinkC></li>				
                    <li className=""><NavLinkC to={'./tools/risk-reward'}>Risk Reward</NavLinkC></li>				
                </ul>
                <span className="main-nav__toggle">&nbsp;</span>
            </li>
            <li className=""><NavLinkC to={'./learn'}>Learn</NavLinkC>
			<ul className="main-nav__sub">				
				<li className=""><NavLinkC to={'./learn/guides'}>Guides</NavLinkC></li>				
				<li className=""><NavLinkC to={'./learn/glossary'}>Glossary</NavLinkC></li>				
			</ul>
            <span className="main-nav__toggle">&nbsp;</span>
		</li>
    
            <li className=""><NavLinkC to={'./trader'}>Trader</NavLinkC>
                <ul className="main-nav__sub">
                    <li className=""><NavLinkC to={'./trader/best'}>Hall of Fame</NavLinkC></li>				
                    <li className=""><NavLinkC to={'./trader/getting-started'}>Trade Now</NavLinkC></li>
                    <li className=""><NavLinkC to={'./trader/channels'}>My Channels</NavLinkC></li>			
                </ul>
                <span className="main-nav__toggle">&nbsp;</span>
            </li>
    
    
    
        </ul>
    </nav>    
    <div className="header-actions">
        {false && <div className={`header-top-bar-toggle d-md-none ${!config.menuShow ? 'hide' : ''}`}>
            <svg role="img" className="df-icon df-icon--joystick">
                <use xlinkHref="/assets/img/necromancers.svg#joystick"/>
            </svg>
            <svg role="img" className="df-icon df-icon--close">
                <use xlinkHref="/assets/img/necromancers.svg#close"/>
            </svg>
        </div>}
        {false && 
        <div className={`header-social-toggle d-none d-md-block ${config.menuShow ? 'hide' : ''}`}>
            <svg role="img" className="df-icon df-icon--thumb-up">
                <use xlinkHref="/assets/img/necromancers.svg#thumb-up"/>
            </svg>
            <span className="header-social-toggle__plus">&nbsp;</span>
            
        </div>
        }
        { false && <div className={`header-cart-toggle ${config.menuShow ? 'hide' : ''}`}>
            <svg role="img" className="df-icon df-icon--bag">
                <use xlinkHref="/assets/img/necromancers.svg#bag"/>
            </svg>
            <svg role="img" className="df-icon df-icon--close">
                <use xlinkHref="/assets/img/necromancers.svg#close"/>
            </svg>
            <span className="header-cart-toggle__items-count">4</span>
        </div>}
        { false && 
        <div className={`header-search-toggle ${config.menuShow ? 'hide' : ''}`}>
            <svg role="img" className="df-icon df-icon--search">
                <use xlinkHref="/assets/img/necromancers.svg#search"/>
            </svg>
            <svg role="img" className="df-icon df-icon--search-close">
                <use xlinkHref="/assets/img/necromancers.svg#search-close"/>
            </svg>
        </div>
        }
        {user==null && 
            <div className={`header-account px-2 ${!config.menuShow ? 'hide' : ''}`} >
               <NavLinkC className="btn btn-lg btn-seoncadry" to={'/login'}>
                    <svg role="img" className="df-icon df-icon--bag">
                        <use xlinkHref="/assets/img/necromancers.svg#login-register"></use>
                    </svg>
               </NavLinkC>
            </div>
        }
        {user!=null && 
        <div className={`header-account ${!config.menuShow ? 'hide' : ''}`} >
              <Button variant="link" size="sm" onClick={ e => dispatch(openUserUpdateProfile()) } style={{'fontSize':'1rem', 'fontWeight':'bold', 'textDecoration':'none'}}>
                <i title="Change Displayname" className="fa-solid fa-gear"></i>{user.displayname}
                </Button>

            <Button variant="link" size="sm" onClick={ e => setAvatarModal({open:true}) } style={{'fontSize':'1rem', 'fontWeight':'bold', 'textDecoration':'none'}}>
                <i title="Upload Avatar" className="fa-solid fa-square-caret-up"></i>                
            </Button>
            <div className="header-account__avatar">          
                <img style={{'width':'40px', 'height':'40px'}}
                 onClick={ e => setAvatarModal({open:true}) } 
                    src={`${BASE_URL}/api/Greet/GetAvatarById/avatar/${user.username}?entity=User`} 
                    srcSet={`${BASE_URL}/api/Greet/GetAvatarById/avatar/${user.username}?entity=User 2x`} alt="" />
            </div>
            <div className="header-account__body" style={{'width':'auto'}}>
                

                <div className="header-account__name">
                    <span title={user.email}>{user.email}</span>
                </div>
                
            </div>
            <div className="header-account__icon">
                <Link className="btn btn-sm btn-link" onClick={e => { dispatch(closeMenu()); } }  to={'/account'} style={{'fontSize':'1rem', 'fontWeight':'bold', 'textDecoration':'none'}}>
                    Profile
                </Link>
               
                
                <a  onClick={() => dispatch(logout())}>
                    <svg role="img" className="df-icon df-icon--logout">
                        <use xlinkHref="/assets/img/necromancers.svg#logout"/>
                    </svg>
                </a>
            </div>
        </div>
        }
        <div className={`header-menu-toggle ${config.menuShow ? 'toggled' : ''}`} onClick={menuShowHide}>
            <div className="header-menu-toggle__inner">
                <span>&nbsp;</span>
                <span>&nbsp;</span>
                <span>&nbsp;</span>
            </div>
        </div>
    </div>   

   {avatarModel.open &&  <UserAvatarUpload show={avatarModel.open} onClose={() => setAvatarModal({open: false})} /> }
   {nickModel &&  <UserUpdateProfile show={nickModel} onClose={() =>  dispatch(closeUserUpdateProfile()) } /> }
</header>);
}

export default Header;