import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { PositionEx , usePostApiPositionCancelOrderByIdCancelorderMutation } from "../../api/positionApi";
import CurrencyView from "../../components/CurrencyView";
import { TraderPagePositionStoplossModal } from "./trader.page.position.stoplossmodal";
import { TraderPagePositionTPModal } from "./trader.page.position.tpmodal";
import { TraderPagePositionPriceModal } from "./trader.page.position.pricemodal";
import { TraderPagePositionQtyModal } from "./trader.page.position.qtymodal";
import PositionTypeBox from "../../components/PositionTypeBox";
import React from "react";
import TimeAgo from 'react-timeago'
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";


const TraderPageOrderRow = (props: { gotoPair: (exchangeId: string, exchangeName: string, pairId: string, pairName: string) => void, pos: PositionEx; pig: () => void; }) => {

	const { t } = useTranslation();
	const [ CancelOrder, { isLoading, isSuccess }] = usePostApiPositionCancelOrderByIdCancelorderMutation();

	const [tppsm, setTppsm] = useState(false);
	const [tpptp, setTpptp] = useState(false);
	const [mdlqty, setMdlqty] = useState(false);
	const [mdlprc, setMdlprc] = useState(false);
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' }) 
	useEffect( ()=>
	{
		props.pig();
		
	},[isSuccess,tppsm , tpptp,mdlqty, mdlprc]);

	const mobileRender = () => {
		if(isTabletOrMobile)
			return(<>
			<td>
				<table style={{'width':'100%'}} className="table-dark table-sm">
					<tr>
					<td colSpan={2}>
								<span onClick={e => props.gotoPair(pos.exchangeId!.toString(), pos.exchangeName!, pos.pairId!.toString(), pos.pairName!)}>
								{pos.pairName} <br/> <PositionTypeBox typ={pos.type!} /> {pos.leverageMax}x</span>
								{' '}<span>{pos.exchangeName}</span>
							</td>
					<td className="text-center">
						<label className="text-muted">{t("Size")}</label>
						<CurrencyView value={pos.orderPrice! * pos.orderQty!} />
					</td>			
					
					<td className="text-end">
						<div className="btn-group btn-group-sm" role="group">
							<button className="btn btn-secondary" disabled={isLoading}  onClick={ (e:any) => CancelOrder({ id: pos.id! })  } >{t("Cancel")}</button>
						</div>
					</td>
					</tr>
					<tr>
					
					
					<td className="text-center">
					<label className="text-muted">Price</label> <br/>
						<CurrencyView value={pos.orderPrice!} /> {' '}
						<Button variant="link" size="sm" onClick={() => setMdlprc(true)}><i className="fa fa-edit" style={{ 'fontSize': '12px' }}></i></Button>
						<TraderPagePositionPriceModal  isOpen={mdlprc} onHide={()=> { setMdlprc(false);} } qty={pos.orderQty!} posId={pos.id!} price={pos.orderPrice!}  />
					</td>
					<td className="text-center">
						<div><label className="text-muted">{t("Amount")}</label> </div>
						{pos.orderQty?.toFixed(5)} {' '} 
						<Button variant="link" size="sm" onClick={() => setMdlqty(true)}><i className="fa fa-edit" style={{ 'fontSize': '12px' }}></i></Button>
						<TraderPagePositionQtyModal  isOpen={mdlqty} onHide={()=> { setMdlqty(false); } } posId={pos.id!} qty={pos.orderQty!} price={pos.entryPrice!}  />
					</td>
					<td className="text-center">
					<div><label className="text-muted">{t("Take Profit")}</label></div>
						({pos.takeProfits?.length})														
						<Button variant="link" size="sm" onClick={() => setTpptp(true)}><i className="fa-solid fa-pen-to-square" style={{  'fontSize': '12px' }} ></i></Button>
						<TraderPagePositionTPModal  isOpen={tpptp} onHide={()=> setTpptp(false)} posId={pos.id!} qty={pos.qty!} price={pos.entryPrice!}  />							
					</td>
					<td className="text-end">
					<div><label className="text-muted">{t("Stoploss")}</label> </div>
						{pos.stopLoss! > 0 ? "$" + pos.stopLoss : " "}
						<Button variant="link" size="sm" onClick={() => setTppsm(true)}><i className="fa-solid fa-pen-to-square" style={{ 'fontSize': '12px' }} ></i></Button>
						<TraderPagePositionStoplossModal isOpen={tppsm} onHide={() => setTppsm(false)} qty={pos.qty!} posId={pos.id!} price={pos.entryPrice!} />					
					</td>
					
					</tr>
			</table>
		</td>
		</>)
	}
	const notMobileRender = () => {
		if(!isTabletOrMobile)
			return (<><td>
				<span onClick={e => props.gotoPair(pos.exchangeId!.toString(), pos.exchangeName!, pos.pairId!.toString(), pos.pairName!)}>
				{pos.pairName} <br /><PositionTypeBox typ={pos.type!} /> {pos.leverageMax}x
				</span>
			</td>			
			<td>{pos.exchangeName}</td>		
			<td className="text-center">
				{pos.orderQty?.toFixed(5)} {' '} 
				<Button variant="link" size="sm" onClick={() => setMdlqty(true)}><i className="fa fa-edit" style={{ 'fontSize': '12px' }}></i></Button>
				<TraderPagePositionQtyModal  isOpen={mdlqty} onHide={()=> { setMdlqty(false); } } posId={pos.id!} qty={pos.orderQty!} price={pos.entryPrice!}  />
			</td>
			<td className="text-center">
				<CurrencyView value={pos.orderPrice! * pos.orderQty!} />
			</td>
			<td className="text-center"><CurrencyView value={pos.orderPrice!} /> {' '}
				<Button variant="link" size="sm" onClick={() => setMdlprc(true)}><i className="fa fa-edit" style={{ 'fontSize': '12px' }}></i></Button>
				<TraderPagePositionPriceModal  isOpen={mdlprc} onHide={()=> { setMdlprc(false);} } qty={pos.orderQty!} posId={pos.id!} price={pos.orderPrice!}  />
			</td>
			<td className="text-center"><TimeAgo date={pos.createdAt!} /></td>
			<td className="text-end">
				<div style={{ 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'space-evenly' }}>
					<div style={{'flexGrow':1}}>
						{pos.takeProfits && pos.takeProfits.map(t => {
							return (<div key={t.id!}><CurrencyView value={t.protfitPrice!} /> - %{t.profitPercentageClosed?.toFixed(2)}</div>);
						})
						}
						{
							(!pos.takeProfits || pos.takeProfits.length ==0 ) && <div></div>
						}
					</div>
					<div>
					<Button variant="link" size="sm" onClick={() => setTpptp(true)}><i className="fa-solid fa-pen-to-square" style={{ 'fontSize': '12px' }} ></i></Button>
						<TraderPagePositionTPModal  isOpen={tpptp} onHide={()=> setTpptp(false)} posId={pos.id!} qty={pos.qty!} price={pos.entryPrice!}  />
					</div>
				</div>
			</td>
			<td className="text-end"><div style={{ 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'space-evenly' }}>
					<div style={{'flexGrow':1}}>
				{pos.stopLoss! > 0 ? "$" + pos.stopLoss : " "}</div>
				<Button variant="link" size="sm" onClick={() => setTppsm(true)}><i className="fa-solid fa-pen-to-square" style={{ 'fontSize': '12px' }} ></i></Button>
				<TraderPagePositionStoplossModal isOpen={tppsm} onHide={() => setTppsm(false)} qty={pos.qty!} posId={pos.id!} price={pos.entryPrice!} />
				</div>
			</td>
			<td className="text-center">{pos.id}</td>
			<td>
				<div className="btn-group btn-group-sm" role="group">
					<button className="btn btn-secondary" disabled={isLoading}  onClick={ (e:any) => CancelOrder({ id: pos.id! })  } >Cancel</button>
				</div>
			</td></>)

	}

	const pos = props.pos;
	return (<tr key={pos.id!}>
		{mobileRender()}
		{notMobileRender()}		
		
	</tr>);
};

const MemoTraderPageOrderRow = React.memo(TraderPageOrderRow);
export { TraderPageOrderRow, MemoTraderPageOrderRow };