import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useBodyClassnames from '../../components/useBodyClassnames';
import { ProfileQrRead, ProfileRead, ProfileStatRecordRead, useGetApiAuthUserProfileQuery, useLazyGetApiAuthUserProfileQuery } from '../../api/authApi';
import Guider from "../../hooks/useGuidBox";
import CurrencyView from '../../components/CurrencyView';
import { useAppDispatch, useAppSelector } from '../../store';
import TraderPageClosed from '../trader/trader.page.closed';
import { openUserUpdateProfile } from '../../../features/userNick/userNickSlice'
import ConfirmBox from "../../components/ConfirmBox";
import { useTranslation } from "react-i18next";
const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT as string;
const ProfilePage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const s_auth = useAppSelector(t => t.auth);
    const { t } = useTranslation();
    const [userId, setUserId] = useState(id || s_auth?.user?.id!);
    const user = useAppSelector(p => p.auth.user);

    const [data, setData] = useState<ProfileQrRead>();
    const [sdata, setSdata] = useState<ProfileStatRecordRead>();
    const [fdata, setFdata] = useState<ProfileStatRecordRead>();
    const [getuser, result, inf] = useLazyGetApiAuthUserProfileQuery();
    const [avatarModel, setAvatarModal] = useState({ open: false });
    const [me, setMe] = useState<boolean>(false);


    useEffect(() => {

        if (userId != (id || s_auth?.user?.id!)) {
            setUserId(id || s_auth?.user?.id!)
            console.warn("*****", id, s_auth, userId);
        }
        console.warn(id, s_auth, userId);

    });


    useEffect(() => {

        setMe(userId == s_auth?.user?.id);
        getuser({ userid: userId });
    }, [userId]);

    useEffect(() => {
        setData(result.data);
    }, [result]);

    useEffect(() => {
        if (data && data.data?.summary) {
            setFdata(data.data?.summary.find(x => x.completed == false));
            setSdata(data.data?.summary.find(x => x.completed == true));
        }
    }, [data]);

    useBodyClassnames(["bg--dotted-3x3", "bg--texture-01", "scroll-is--active"]);
    return (<main className="site-content team-info-page" id="wrapper">
        <div className="container container--large">
            <div className="team-carousel">
                {data &&
                    <div className="team-carousel__content slick-initialized slick-slider slick-dotted slick-vertical">
                        <div className="slick-list draggable" style={{ 'overflow': 'inherit' }}>
                            <div className="slick-track" style={{ 'paddingBottom': '72px' }}>

                                <div className="team-carousel__item slick-slide slick-current slick-active">
                                    <div className='row'>
                                        <div className="col-lg-6">
                                            <h3 className="player-info-subtitle h4 text-uppercase">
                                                <Guider guid={data.data?.id!} />


                                            </h3>

                                            <h2 className="player-info-title h1">{

                                                me ? user?.displayname ? user.displayname : <button onClick={e => { console.warn(111); dispatch(openUserUpdateProfile()) }}
                                                    className='btn btn-sm btn-success' style={{ 'fontSize': '18px', 'letterSpacing': '3px', 'color': 'black' }}>
                                                    Please set how world will know you.
                                                </button> :
                                                    data.data?.displayName ? data.data.displayName
                                                        : <span style={{ 'fontSize': '1rem', 'letterSpacing': 'initial' }}>Known as {data.data?.id}</span>



                                            }


                                            </h2>
                                            <div className="row">


                                                <div className="col-12 col-md-6 col-xl-6">
                                                    <div className="player-info-detail">
                                                        <div className="player-info-detail__label text-success">{t("Closed Positions")}</div>
                                                        <div className="player-info-detail__title">
                                                            <label className="matches-filter__label">Count: {sdata?.count || 0} </label>
                                                            <label className="matches-filter__label">{t("Best Position")}: <CurrencyView value={sdata?.maxPnl || 0} /> </label>
                                                            <label className="matches-filter__label">{t("Worst Position")}: <CurrencyView value={sdata?.minPnl || 0} /> </label>
                                                            <label className="matches-filter__label">{t("Total Profit")}: <CurrencyView value={sdata?.sumPnl || 0} /> </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-6 col-xl-6">
                                                    <div className="player-info-detail">
                                                        <div className="player-info-detail__label text-success">{t("Open Positions")}</div>
                                                        <div className="player-info-detail__title">
                                                            <label className="matches-filter__label">{t("Count")}: {fdata?.count || 0} </label>
                                                            <label className="matches-filter__label">{t("Best Position")}: <CurrencyView value={fdata?.maxPnl || 0} /> </label>
                                                            <label className="matches-filter__label">{t("Worst Position")}: <CurrencyView value={fdata?.minPnl || 0} /> </label>
                                                            <label className="matches-filter__label">{t("Total Profit")}: <CurrencyView value={fdata?.sumPnl || 0} /> </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-12 col-xl-12">
                                                    <div className="player-info-detail ">
                                                        <div className="player-info-detail__label text-success">{t("Wallet")}</div>
                                                        <div className="player-info-detail__title">{data.data?.ethereumWallet}</div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-xl-12">
                                                    <div className="player-info-detail">
                                                        <div className="player-info-detail__label text-success">{t("Since")}</div>
                                                        <div className="player-info-detail__title">{data.data?.registerAt}</div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-xl-12">
                                                    <div className="player-info-detail">
                                                        <div className="player-info-detail__label"></div>
                                                        <div className="player-info-detail__title"></div>
                                                    </div>
                                                </div>

                                                {false && <><div className="col-6 col-md-6 col-xl-4">
                                                    <div className="player-info-detail">
                                                        <div className="player-info-detail__label"></div>
                                                        <div className="player-info-detail__title"></div>
                                                    </div></div>
                                                    <div className="col-6 col-md-6 col-xl-4"><div className="player-info-detail">
                                                        <div className="player-info-detail__label"></div>
                                                        <div className="player-info-detail__title"></div></div></div>
                                                    <div className="col-6 col-md-6 col-xl-4"><div className="player-info-detail">
                                                        <div className="player-info-detail__label"></div>
                                                        <ul className="social-menu social-menu--default">
                                                            <li><a href="https://www.facebook.com/danfisher.dev/" target="_blank" ></a></li>
                                                            <li><a href="https://twitter.com/danfisher_dev" target="_blank" ></a></li>
                                                            <li><a href="https://twitch.tv" target="_blank" ></a></li>
                                                            <li><a href="https://www.instagram.com/dan.fisher.dev/" target="_blank" ></a></li>
                                                        </ul>
                                                    </div>
                                                    </div></>}

                                            </div>


                                        </div>

                                        <div className="col-lg-3" >
                                            <div className="team-player__photo"><img style={{ 'border': '8px solid var(--success)', 'maxWidth': '100%' }}
                                                onClick={e => setAvatarModal({ open: true })}
                                                src={`${BASE_URL}/api/Greet/GetAvatarById/avatar/${userId}?entity=User`}
                                                srcSet={`${BASE_URL}/api/Greet/GetAvatarById/avatar/${userId}?entity=User 2x`} alt="" />
                                            </div>
                                        </div>
                                        <div className="d-block d-sm-none mt-4"></div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className='col-md-12'>
                                                    <h2 className='text-success'>Owned Channels</h2>
                                                    {
                                                        data.data?.owned_chan?.map((c, i) => {
                                                            return (<p>
                                                                <span onClick={e => navigate('/channel/' + c.id)} >
                                                                    <b>{i + 1}. {' '} {c.name}</b>
                                                                    <span className="text-bold text-muted badge">{' '}{c.chanType}</span>
                                                                </span>
                                                                <i className="fa fa-link" /> </p>);
                                                        })
                                                    }
                                                    {
                                                        data.data?.owned_chan?.length == 0 && <p className='text-warning'>{t("There is no channel found")}!</p>
                                                    }
                                                    <p className='mb-5'>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className='col-md-12'>
                                                    <h2 className='text-success'>Trading Channels</h2>
                                                    {
                                                        data.data?.prove_chan?.map((c, i) => {
                                                            return (<p>
                                                                <span onClick={e => navigate('/channel/' + c.id)}><b>{i + 1}. {' '} {c.name}</b>
                                                                    <span className="text-bold text-muted badge">{' '}{c.chanType}</span>{' '}
                                                                    <i className="fa fa-link" /> {' '}</span>
                                                                    {me &&
                                                                        <ConfirmBox btnName="Leave" message="Are you really want to leave?" title="Are you sure?" onConfirm={() => alert(1)} />
                                                                    }
                                                            </p>);
                                                        })
                                                    }
                                                    {
                                                        data.data?.prove_chan?.length == 0 && <p className='text-warning'><i className="fa-solid fa-triangle-exclamation"></i> There is no channel found!</p>
                                                    }
                                                    <p className='mb-5'>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className='col-md-12'>
                                                    <h2 className='text-success'>{t("Subscribed Channels")}</h2>
                                                    {
                                                        data.data?.subss_chan?.map((c, i) => {
                                                            return (<p>
                                                                    <span  onClick={e => navigate('/channel/' + c.id)}>
                                                                        <b>{i + 1}. {' '} {c.name}</b>
                                                                        <span className="text-bold text-muted badge">{' '}{c.chanType}</span>
                                                                        <i className="fa fa-link" /> {' '}</span>
                                                                        {me && <ConfirmBox btnName="Leave" message="Are you really want to leave?" title="Are you sure?" onConfirm={() => alert(1)} />}                                                                    
                                                                </p>);
                                                        })
                                                    }
                                                    {
                                                        data.data?.subss_chan?.length == 0 && <p className='text-warning'><i className="fa-solid fa-triangle-exclamation"></i> There is no channel found!</p>
                                                    }
                                                    <p className='mb-5'>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h2 className='text-success'>{t("Closed Positions")}</h2>
                                                    <span>{t("Last 50 Positions")}</span>
                                                    <TraderPageClosed targetUser={id} />

                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                }
                {!data && <h3 style={{ 'marginTop': '64px', 'height': '300px', 'color': 'white', 'display': 'flex', 'alignSelf': 'center', 'justifySelf': 'center' }}>User seems either not exists or removed :)</h3>}
            </div>

        </div>
    </main>)
}


export default ProfilePage;