
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './PriceBox.scss'

export interface PriceBoxProps  {
    Name: string
    Symbol: string,
    price: number,
    glimmer?: boolean
}


const PriceBox = (props: PriceBoxProps) => {

    const [prevV, setPrevV] = useState<number | null>(null);
    const [cn,setCn] = useState("");
    const [timeId, setTimeId] = useState<NodeJS.Timeout | null>(null);


    const formatter2 = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits:2,            
      });
      const formatter3 = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits:3,            
      });
      const formatter5 = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits:5,
      });
      const formatter8 = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits:8,
      });

    const fmt = ( v:number ) => {
        
          
        if(v>100) return formatter2.format(v);
        if(v>10) return formatter3.format(v)
        if(v>1) return formatter5.format(v);
        return formatter2.format(8)
    }


    useEffect(()=> {
        if(timeId!=null)
            clearTimeout(timeId);
        if(prevV !== null) {
            if(props.price > prevV) {
                setCn("up");
            }
            else if(props.price < prevV) {
                setCn("down");
            }
            else setCn("");        
        } else setCn("up");
        setPrevV(props.price);        


        setTimeId( setTimeout(()=> setCn(""), 250) );

    },[props.price]);

    let lowername = "fab fa-"+props.Name.toLowerCase();
    if(props.glimmer) lowername="fas fa-coins";
    return(<Link to={`./currencies/detail/${props.Symbol}`} className="priceBox" lang='en' rel="noreferrer">
                <i className={lowername+" glitch-layer glitch-layer--1"}></i>
                <i className={lowername+" glitch-layer glitch-layer--2"}></i>
                <i className={lowername}></i>
                <span className={`link-subtitle ${cn}`}>{fmt(props.price)}</span>{props.Name}
            </Link>)

}

export default PriceBox;