import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { greetApi } from '../app/api/greetApi';
import { RootState, AppThunk } from '../app/store';

export interface IConfigState {
    menuShow : boolean,    
    loginToastUp : boolean
}
const initialState: IConfigState = {
    menuShow : false,    
    loginToastUp:false
};

const configSlice = createSlice({
    initialState,
    name: 'config',    
    reducers: {

        showLoginToast(state) { state.loginToastUp=true; },
        hideLoginToast(state) { state.loginToastUp=false; },

        openMenu(state) { 
            state.menuShow = true;
        },
        closeMenu(state) {
            state.menuShow=false;
        },
        toggleMenu(state) {            
            state.menuShow = !state.menuShow;
        }

    },
    extraReducers: (builder) => {
    },
  });
  
  export const { toggleMenu, closeMenu, openMenu, showLoginToast, hideLoginToast } = configSlice.actions;
  
  export default configSlice.reducer;