import { useEffect, useState } from "react";
import Decors from "../partials/Decors";
import { useTranslation } from "react-i18next";
interface PNLOpt {
    max_open_coin : number;
    entry_price: number |null;
    max_open_usdt: number;    
    balance:number |null;
    is_long:boolean;
    leverage: number;
}
const ToolMaxOpen = () => {
    const { t } = useTranslation();
    const [tx, sett] = useState<PNLOpt>({
            entry_price:null,
            is_long:true,
            max_open_coin:0,
            max_open_usdt:0,
            balance:null,     
            leverage:1               
    });


    const goodNum = (n:number) =>  {

       return new Intl.NumberFormat("en-US", {
            signDisplay: "exceptZero",
            minimumFractionDigits: 2,
            maximumFractionDigits: 10
        }).format(n);

    }

    useEffect(()=> {

        const cpt = {...tx};


        if(cpt.entry_price != null && cpt.balance != null) {
            if(cpt.is_long) {
                cpt.max_open_usdt = cpt.balance * cpt.leverage 
                cpt.max_open_coin = cpt.balance * cpt.leverage / cpt.entry_price
            }else {
                cpt.max_open_usdt = cpt.balance * cpt.leverage 
                cpt.max_open_coin = cpt.balance * cpt.leverage / cpt.entry_price
            }
        }

        sett(cpt);
       
        
    },[tx.entry_price, tx.balance, tx.leverage, tx.is_long]);


    return (
        <main className="site-content" id="wrapper">			
			<div className="site-content__inner">
            <div className="site-content__holder">
            <Decors>                                                   
                {' '}
            </Decors>

                <div className="page-content">
                <h4 className="mb-5">Max Open</h4>
                <div className="row ">
                    <div className="col-md-6">


                        <div className="form-group">

                            <input type="radio" className="btn-check" name="options-outlined" id="success-outlined" autoComplete="off" defaultChecked 
                             onChange={e => sett({...tx, is_long:true } )} 
                            />
                            <label className="btn btn-outline-success" htmlFor="success-outlined">Long</label>

                            <input type="radio" className="btn-check" name="options-outlined" id="danger-outlined" autoComplete="off" 
                            onChange={e => sett({...tx, is_long:false } )} 
                            />
                            <label className="btn btn-outline-danger" htmlFor="danger-outlined">Short</label>

                        </div>

                        <div className="form-group">                            
                            <label htmlFor="customRange3" className="form-label">Leverage (x{tx.leverage})</label>
                            <input type="range" className="form-range" min="1" max="200" value={tx.leverage} 
                            onChange={e => sett({...tx, leverage: e.target.value ? parseInt(e.target.value) : 1} )}  step="1" id="customRange3"></input>                            
                        </div>

                        <div className="form-group">
                            <label className="form-label" htmlFor="input-default">{t("Entry Price")}</label>
                            <div className="input-group mb-3">
                                <span className="input-group-text">$</span>
                                <input type="number" value={tx.entry_price || ""}  
                                onChange={e => sett({...tx, entry_price:parseFloat(e.target.value??0)} )} className="form-control" placeholder="1.5" />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="form-label" htmlFor="input-default">Balance</label>
                            <div className="input-group mb-3">
                            <span className="input-group-text">$</span>
                                <input type="number"   value={tx.balance|| ""}  onChange={e => sett({...tx, balance:parseFloat(e.target.value??0)} )} className="form-control" placeholder="100" />
                            </div>
                        </div>
                                            

                    </div>
                    <div className="col-md-6">
                    <h4>Result</h4>
                    <div className="form-group">
                            <label className="form-label" htmlFor="input-default">Max Open ($)</label>
                            <div className="text-success"  style={{'fontWeight':'bold'}}>{goodNum(tx.max_open_usdt)} $T</div>
                        </div>
                        <div className="form-group">
                            <label className="form-label" htmlFor="input-default">Max Open (Coin)</label>
                            <div className="text-success"  style={{'fontWeight':'bold'}}>{goodNum(tx.max_open_coin)} Coin</div>
                        </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>    
        )
}

export default ToolMaxOpen;