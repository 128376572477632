import { Button, ButtonGroup } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { ChanStat, ChanStatListQrRead, useGetApiChannelGetChannelStatsByIdQuery, usePostApiChannelGetInviteMutation, usePostApiChannelInviteMutation, usePostApiChannelInviteResponseMutation } from "../../api/channelApi";
import { useEffect, useRef, useState } from "react";
import TimeAgo from 'react-timeago'
import { toast } from "react-toastify";
import useBodyClassnames from "../../components/useBodyClassnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { useGetApiChannelGetChannelByIdQuery, useGetApiChannelGetPositionProviderByIdProvidersQuery } from "../../api/channelApi";
import Avatar from "react-avatar";
import ChannelPageClosed from "./channel.page.closed";
import ErrorDisplay from "../partials/ErrorDisplay";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { entries } from "lodash";
import { useAppSelector } from "../../store";
import handleApiErrors from "../../components/handleApiErrors";
const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT as string;


type rated = {
    avgDailyPositions: number;
    roi: number;
    totalPnl: number;
    totalPositions: number;
    winRatio: number;
}

type sumed = {
	total:number;
	count:number;
}

const ChannelPage = () => {

  const { chanId } = useParams();
  const {data, isLoading, isError, error } = useGetApiChannelGetChannelByIdQuery({ id: parseInt(chanId!) });
	const {data: pData, isLoading: pDataIsLoading } = useGetApiChannelGetPositionProviderByIdProvidersQuery({ id: parseInt(chanId!) });
	const {data: sData, isLoading: sDataIsLoading, isSuccess:sIsSuccess } = useGetApiChannelGetChannelStatsByIdQuery({ id: parseInt(chanId!) });
	const [rate, setRate] = useState<rated|undefined>();
	const [lError, setLError] = useState<FetchBaseQueryError | undefined>(undefined);
  const [createChannelInvite, { isLoading : iLoading, isError: iError, isSuccess: iSuccess, data: iData, error: zError }] = usePostApiChannelInviteMutation();
  const { t } = useTranslation();
  const auth_store = useAppSelector( p => p.auth);
	const [activeSection, setActiveSection] = useState<string>('overview');
	const overviewRef = useRef<HTMLDivElement | null>(null);
	const historyRef = useRef<HTMLDivElement | null>(null);
	const statsRef = useRef<HTMLDivElement | null>(null);
	
  useBodyClassnames(["bg--dotted-3x3","bg--texture-01","scroll-is--active", "team-info-page"]);

	const handleScroll = (id: string) => {
		document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
		setActiveSection(id);
  };

  useEffect(() => {
    console.log(iError,iData, zError)
    if(iError && zError) {
      handleApiErrors((zError as any)?.data?.errors);
    }
    if(iSuccess) {
      toast.success("Invitation request has been sent to owner, please check ")
    }

  },[iData, zError]);


  useEffect(() => {
		const observer = new IntersectionObserver(			
		  (entries) => {
			
			entries.forEach((entry) => {
				console.log(entry, entry.isIntersecting);
			  if (entry.isIntersecting) {
				setActiveSection(entry.target.id);
			  }
			});
		  },
		  { threshold: 0.6 }
		);
	
		if (overviewRef.current) observer.observe(overviewRef.current);
		if (historyRef.current) observer.observe(historyRef.current);
		if (statsRef.current) observer.observe(statsRef.current);
	
		return () => {
		  if (overviewRef.current) observer.unobserve(overviewRef.current);
		  if (historyRef.current) observer.unobserve(historyRef.current);
		  if (statsRef.current) observer.unobserve(statsRef.current);
		};
	  }, []);
	  

	function calculateMetrics(transactions: ChanStat[]) : rated {
		let dailyPositions: { [date: string]: { total: number; count: number } } = {};
		let totalPnl = 0;
		let totalPositions = 0;
		let winningPositions = 0;
	
		transactions.forEach(transaction => {
			if (transaction.completed === true) {
				const date = transaction.createdAt!;
				const pnl = transaction.sumPnl!;
				const count = transaction.count!;
				const pnlp = transaction.pnlP!;
				// AVG DAILY POSITIONS
				dailyPositions[date] = dailyPositions[date] || { total: 0, count: 0 };
				dailyPositions[date].total += count;
				dailyPositions[date].count++;
	
				// TOTAL PNL
				totalPnl += pnl;
	
				// TOTAL POSITIONS
				totalPositions += count;
	
				// WIN RATIO				
				winningPositions += pnlp;
				
			}
		});
	
    let avgDailyPositions=0;
    if(transactions.length>0)
    {  
      const total_pos = transactions.reduce((acc, obj:ChanStat) => acc + obj.count!,0);
      const givenDate = new Date(transactions[0].createdAt!);
      const currentDate = new Date();
      const timeDifference = currentDate.getTime() - givenDate.getTime();
      const totalDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      avgDailyPositions = total_pos / totalDays;
    }


		//const avgDailyPositions = Math.round( Object.values(dailyPositions).reduce((acc:number, curr:sumed) => acc + curr.total / curr.count, 0));

		// Calculate ROI (Return on Investment)
		const roi = Math.round(totalPnl / totalPositions);
	
		// Calculate WIN RATIO
		const winRatio = 100.0 *( winningPositions / totalPositions);
	
		totalPnl = Math.round(totalPnl);
		
		return {
			avgDailyPositions: (Math.round(avgDailyPositions * 100) / 100),
			roi: (Math.round(roi * 100) / 100),
			totalPnl: (Math.round(totalPnl * 100) / 100),
			totalPositions,
			winRatio: (Math.round(winRatio * 100) / 100)
		};
	}

	const isFetchBaseQueryError = (error: unknown): error is FetchBaseQueryError => {
		return (
		  typeof error === 'object' &&
		  error !== null &&
		  'status' in error &&
		  'data' in error
		);
	  };

	useEffect( () => {	
		if(sData && sIsSuccess) {
			const s = sData?.data;
			if(s!=null) {              
				setRate(calculateMetrics(s));
            }
		}
	},[sIsSuccess]);

	useEffect(()=> {
		
		if(isFetchBaseQueryError(error))
			setLError(error);

	},[error])

    return (
      <React.Fragment>
        {isError && (
          <main className="site-content" id="wrapper">
            <div className="container">
              <ErrorDisplay
                logoSrc="/assets/img/logo@2x.png"
                title={lError?.status.toString()!}
                subtitle={t("Access Required")}
                description="Sorry! You need to login to see this content!."
                linkHref="/login"
                linkText="Click here"
              />
            </div>
          </main>
        )}
        {!isError && (
          <>
            <div className="container container--large">
              <div className="row">
                <div className="col-lg-8">
                  <div className="team-carousel">
                    <div className="team-carousel__content">
					<ul className="slick-dots slick-dots-fixed" role="tablist" style={{'zIndex':1999}}>
                        <li className={activeSection === 'overview' ? 'slick-active' : ''} role="presentation"  onClick={() => handleScroll('overview')}>
                          <svg
                            role="img"
                            className="df-icon df-icon--team-overview"
                          >
                            <use xlinkHref="/assets/img/necromancers.svg#overview"></use>
                          </svg>
                        </li>
						
                        
                        <li role="presentation" className={activeSection === 'stats' ? 'slick-active' : ''}  onClick={() => handleScroll('stats')}>
                          <svg role="img" className="df-icon df-icon--stats">
                            <use xlinkHref="/assets/img/necromancers.svg#stats"></use>
                          </svg>
                        </li>
						<li role="presentation" className={activeSection === 'history' ? 'slick-active' : ''} onClick={() => handleScroll('history')}>
                          <svg
                            role="img"
                            className="df-icon df-icon--achievements"
                          >
                            <use xlinkHref="/assets/img/necromancers.svg#achievements"></use>
                          </svg>
                        </li>
                      </ul>
                      <div className="slick-list draggable">
                        <div id="overview" ref={overviewRef}
                          className="team-carousel__item" style={{'minHeight':'100vh'}}
                          data-icon="overview"
                        >
                          <div className="row">
                            <div className="col-lg-11">
                              <h3 className="player-info-subtitle h4 text-uppercase">
                                {data?.data?.channelType == 0
                                  ? "Public"
                                  : "Private"}
                              </h3>
                              <div className="row">
                                <div className="col-md-6 offset-sm-2 offset-md-6">
                                { auth_store != null && data?.data?.ownerId != auth_store.userToken?.id && <div className="btn-group">
                                  <button className="btn btn-sm btn-secondary" disabled={iLoading || isLoading }
                                  onClick={async ()=> {

                                    await createChannelInvite({channelInviteModel: { 
                                        inivteAsTrader:true, 
                                        subExpireDaysAfter:7,
                                        toChannelId: data?.data?.id!,
                                        toUserId : auth_store.user?.id!
                                        } 
                                      });
                                  } }><i className="fa-solid fa-user-astronaut"></i> Join As Trader</button>
                                  <button className="btn btn-sm btn-success" disabled={iLoading || isLoading } 
                                  onClick={async ()=> {

                                    await createChannelInvite({channelInviteModel: { 
                                      inivteAsTrader:false, 
                                      subExpireDaysAfter:7,
                                      toChannelId: data?.data?.id!,
                                      toUserId : auth_store.user?.id!
                                      } 
                                    });
                                } }><i className="fa-solid fa-circle-user"></i>Join As Member</button>
                              
                              </div>
                                  }
                                </div>
                              </div>
                              <h2 className="player-info-title h1 text-uppercase">
                                {data?.data?.name}
                              </h2>
                              
                              <div className="row">
                                <div className="col-6 col-md-6 col-xl-4">
                                  <div className="player-info-detail">
                                    <div className="player-info-detail__label">
                                      Owner
                                    </div>
                                    <div
                                      className="player-info-detail__title"
                                      style={{ color: "white" }}
                                    >
                                      <Link
                                        to={"/account/" + data?.data?.ownerId}
                                      >
                                        {data?.data?.ownerId}
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6 col-md-6 col-xl-4">
                                  <div className="player-info-detail">
                                    <div className="player-info-detail__label">
                                      Since
                                    </div>
                                    <div
                                      className="player-info-detail__title"
                                      style={{ color: "white" }}
                                    >
                                      <TimeAgo
                                        date={data?.data?.createdAt!}
                                      ></TimeAgo>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6 col-md-6 col-xl-4">
                                  <div className="player-info-detail">
                                    <div className="player-info-detail__label">
                                      Total Profit
                                    </div>
                                    <div className="player-info-detail__title color-primary">
                                      ${rate?.totalPnl}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <p>{data?.data?.description}</p>
                            </div>
                          </div>
                        </div>

                        <div ref={statsRef} className="team-carousel__item" style={{'minHeight':'100vh'}} id="stats" data-icon="stats">
                          <div className="row">
                            <div className="col-lg-11">
                              <h3 className="player-info-subtitle h4 text-uppercase">
                                {data?.data?.channelType == 0
                                  ? "Public"
                                  : "Private"}
                              </h3>
                              <h2 className="player-info-title h1 text-uppercase">
                                {data?.data?.name}
                              </h2>
                              <div className="row row-mb-balance">
                                <div className="col-6 col-md-4 col-lg-6 col-xl-4">
                                  <div className="player-info-detail player-info-detail--value">
                                    <div className="player-info-detail__value h3">
                                      {rate?.avgDailyPositions || 0}
                                    </div>
                                    <div className="player-info-detail__body">
                                      <div className="player-info-detail__label">
                                        AVG Daily
                                      </div>
                                      <div className="player-info-detail__title">
                                        Positions
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6 col-md-4 col-lg-6 col-xl-4">
                                  <div className="player-info-detail player-info-detail--value">
                                    <div className="player-info-detail__value h3">
                                      %{rate?.roi || 0}
                                    </div>
                                    <div className="player-info-detail__body">
                                      <div className="player-info-detail__label">
                                        total
                                      </div>
                                      <div className="player-info-detail__title">ROI</div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6 col-md-4 col-lg-6 col-xl-4">
                                  <div className="player-info-detail player-info-detail--value">
                                    <div className="player-info-detail__value h3">
                                      ${rate?.totalPnl || 0}
                                    </div>
                                    <div className="player-info-detail__body">
                                      <div className="player-info-detail__label">
                                        Total
                                      </div>
                                      <div className="player-info-detail__title">
                                        PnL
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6 col-md-4 col-lg-6 col-xl-4">
                                  <div className="player-info-detail player-info-detail--value">
                                    <div className="player-info-detail__value h3">
                                      {(pData?.data?.length || 0) + 1}
                                    </div>
                                    <div className="player-info-detail__body">
                                      <div className="player-info-detail__label">
                                        Total
                                      </div>
                                      <div className="player-info-detail__title">
                                        Members
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6 col-md-4 col-lg-6 col-xl-4">
                                  <div className="player-info-detail player-info-detail--value">
                                    <div className="player-info-detail__value h3">
                                      {rate?.totalPositions || 0}
                                    </div>
                                    <div className="player-info-detail__body">
                                      <div className="player-info-detail__label">
                                        Total
                                      </div>
                                      <div className="player-info-detail__title">
                                        Positions
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6 col-md-4 col-lg-6 col-xl-4">
                                  <div className="player-info-detail player-info-detail--bar">
                                    <div
                                      className="player-info-detail__bar"
                                      data-value={rate?.winRatio || 0}
                                      data-id="progress-path-1"
                                    >
                                      <svg
                                        width="61"
                                        height="61"
                                        viewBox="0 0 61 61"
                                      >
                                        <path
                                          fill="none"
                                          stroke="#000"
                                          strokeWidth="6"
                                          d="M3.008,29.000 L29.009,3.009 L55.009,29.000 L29.009,54.991 L3.008,29.000 Z"
                                        />
                                        <path
                                          fill="none"
                                          id="progress-path-1"
                                          stroke="#00FF00" // Green color
                                          strokeWidth="6"
                                          strokeLinecap="square"
                                          strokeDasharray="152"
                                          strokeDashoffset={
                                            rate?.winRatio! || 0
                                          }
                                          d="M3.008,29.000 L29.009,3.009 L55.009,29.000 L29.009,54.991 L3.008,29.000 Z"
                                        />
                                      </svg>
                                      <i className="fa fa-star">&nbsp;</i>
                                    </div>
                                    <div className="player-info-detail__body">
                                      <div className="player-info-detail__label">
                                        Win ratio
                                      </div>
                                      <div className="player-info-detail__title">
                                        %{rate?.winRatio! || 0}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div  ref={historyRef} className="team-carousel__item" style={{'minHeight':'100vh'}} id="history" data-icon="history">
                          <div className="row">
                            <div className="col-md-12 pb-5 mb-5">
                              <h2 className="text-success">{t("Closed Positions")}</h2>
                              <p className="text-muted">{t("Last 50 Positions")}</p>
                              <ChannelPageClosed  chanId={parseInt(chanId!)} />
                              
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="team-player">
              <div className="container container--large">
                <div className="row">
                  <div className="col-md-12 col-lg-4 offset-lg-7 team-player__inner">
                    <div className="ncr-page-decor">
                      <div className="ncr-page-decor__layer-1">
                        <div className="ncr-page-decor__layer-bg"></div>
                      </div>
                      <div className="ncr-page-decor__layer-2">
                        <div className="ncr-page-decor__layer-bg"></div>
                      </div>
                      <div className="ncr-page-decor__layer-3"></div>
                      <div className="ncr-page-decor__layer-4"></div>
                      <div className="ncr-page-decor__layer-5"></div>
                    </div>

                    <ul className="team-player__filter list-unstyled nav">
                    <li
                            key={data?.data?.ownerId!}
                            className="team-player__filter-item"
                            title={data?.data?.ownerId!}
                          >
                            <Link
                              to={"/account/" + data?.data?.ownerId!}
                              role="tab"
                              data-toggle="tab"
                              className="team-player__filter-inner active"
                              title={data?.data?.ownerId!}
                            >
                              <img
                                src={`${BASE_URL}/api/Greet/GetAvatarById/avatar/${data?.data?.ownerId!}?entity=User`}
                                srcSet={`${BASE_URL}/api/Greet/GetAvatarById/avatar/${data?.data?.ownerId!}?entity=User 2x`}
                                className="team-player__filter-img"
                                alt={data?.data?.ownerId!}
                              />
                            </Link>
                          </li>
                      {pData?.data?.map((p) => {
                        return (
                          <li
                            key={p.userId}
                            className="team-player__filter-item"
                            title={p.userId!}
                          >
                            <Link
                              to={"/account/" + p.userId}
                              role="tab"
                              data-toggle="tab"
                              className="team-player__filter-inner active"
                              title={p.userId!}
                            >
                              <img
                                src={`${BASE_URL}/api/Greet/GetAvatarById/avatar/${p.userId}?entity=User`}
                                srcSet={`${BASE_URL}/api/Greet/GetAvatarById/avatar/${p.userId}?entity=User 2x`}
                                className="team-player__filter-img"
                                alt={p.userId!}
                              />
                            </Link>
                          </li>
                        );
                      })}
                    </ul>

                    <div className="tab-content">
                      <div
                        className="tab-pane fade show active"
                        id="player-0"
                        role="tabpanel"
                      >
                        <div className="team-player__info">
                          <div className="team-player__header">
                            <h2 className="team-player__name h6 color-primary">
                              {" "}
                            </h2>
                            {false && (
                              <h1 className="team-player__nickname h3">
                                <span className="position-relative">
                                  <a
                                    className="add-icon"
                                    href="team-player-1.html"
                                  ></a>
                                </span>
                              </h1>
                            )}
                            <ul className="social-menu social-menu--default">
                              {data?.data?.linkedList!.filter((p)=> p.verified === true).map((p) => {
                                return (
                                  <li className="n">
                                    <a href={p.url!} target="_blank">
                                      {p.icon} {p.lType}:  {p.name}
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                          <div className="team-player__photo">
                            <Avatar
                              name={data?.data?.name!}
                              src={`${BASE_URL}/api/Greet/GetAvatarById/avatar/${chanId}?entity=Channel`}
                              size="256"
                              round={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </React.Fragment>
    );

}


export default ChannelPage;